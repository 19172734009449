import { Row, Col, Form, Radio, Input, Select, Divider } from "antd";
import { useState } from "react";
const { Option } = Select;
const CS_SummaryScreen = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: CONTACT SUMMARY SCREEN (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
        <Row></Row>
      </Row>
      <Form.Item
        name="What is your current address?"
        label="What is your current address?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Are you able to receive mail at this address?"
        label="Are you able to receive mail at this address?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default CS_SummaryScreen;
