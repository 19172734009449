import { Button, Form, Input, message, Space, Card, Segmented } from "antd";
import { useEffect, useState, useRef } from "react";
import useTextFetch from "../hooks/useTextFetch";
import useUpdateEffect from "../hooks/useUpdateEffect";
import { REGEX, LAMBDAS } from "../literals";
import ConvertToFormUrlEncoded from "../utils";
import Modal from "react-modal";
import useUpdateLogger from "../hooks/useUpdateLogger";
import SelectBox from "devextreme-react/select-box";
import List from "devextreme-react/list";
import { templates } from "../assets/textTemplates";
import { interviewTemplates } from "../assets/interviewTemplates";
import { fcccTemplates } from "../assets/fcccTemplates";
import { esTemplates } from "../assets/esTemplates";
import { customTemplates } from "../assets/customTemplates";
import { customDropdown } from "../assets/customDropdown";
import SelectSearch from "react-select-search";

import Select from "react-select";
const { TextArea } = Input;

const options = [
  {
    value:
      "The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
    label:
      "Apply: The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
  },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
  { value: "vanilla", label: "Chocholate" },
];

function ItemTemplate(data) {
  return <div>{data.Content}</div>;
}

const phone = "Phone";
const textArea = "message";

const customStyles = {
  content: {
    width: "50%",
    height: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const SMSForm = (props) => {
  const [textValue, setTextValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("General");
  const [selectedOption, setSelectedOption] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [contactID, setContactID] = useState("");
  const [routingProfile, setRoutingProfile] = useState("");
  const [agentID, setAgentID] = useState("");
  const [xId, setXId] = useState("");
  const [searchMode, setSearchMode] = useState("contains");
  const [form] = Form.useForm();
  const [payLoad, setpayLoad] = useState(null);
  const { data, isFecthingData, error } = useTextFetch(
    LAMBDAS.TEXT_TO_PHONE,
    payLoad,
    "POST",
    "application/json"
  );
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSpanish, setIsSpanish] = useState(false);
  const [objValue, setObject] = useState({});
  const [progValue, setProgValue] = useState("Select Program");
  const [progLink, setProgLink] = useState("");
  const [caseNumber, setCaseNumber] = useState("");

  const [fieldOne, setFieldOne] = useState("");
  const [fieldTwo, setFieldTwo] = useState("");
  const [esTwo, setEsTwo] = useState("");

  const onFinish = () => {
    if (phoneValue.length > 0 && form.getFieldValue(textArea).length > 0) {
      console.log("NGENGU PAYLOAD: ", form.getFieldValue(textArea));
      // setpayLoad(ConvertToFormUrlEncoded(form.getFieldsValue()));
      var newPayload = {
        Phone: phoneValue,
        contactID: contactID,
        message: form.getFieldValue(textArea),
        agentID: agentID,
        xID: xId,
      };
      setpayLoad(newPayload);
      form.setFieldsValue({
        message: "",
      });
      setCaseNumber("");
      setFieldOne("");
      setFieldTwo("");
      setEsTwo("");
      console.log("FORM VALUE: ", form);
      console.log("New Payload: ", newPayload);
    }
  };

  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      var xID = e.detail.xID;
      var routingProfile = e.detail.routingProfile;

      setAgentID(agentID);
      setXId(xID);
      setRoutingProfile(routingProfile);
    },
    false
  );

  const customStyles = {
    // control represent the select component
    container: (provided) => ({
      ...provided,
      width: "80%",
    }),
  };

  useUpdateEffect(() => {
    console.log("Use Update Effect");
    // eslint-disable-next-line no-sequences
    if (phoneValue.length > 0) {
      console.log("Inside USE UPDATE EFFECT for sending message");
      console.log("Data: ", data);
      console.log("Error: ", error);

      switch ((data === null, error === null)) {
        case (false, true):
          console.log("INSIDE FALSE TRUE");
          console.log("BGEBGEGE");
          // message.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            message: "",
          });
          break;
        case (true, false):
          console.log("INSIDE TRUE FALSE");
          // message.error(`Text failed to send to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            message: "",
          });
          break;
        default:
          // message.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            message: "",
          });
          break;
      }
    }
  }, [error, data]);

  useEffect(() => {
    setPhoneValue(props.customerNumber);
    setContactID(props.contactID);
    console.log(
      "Inside SMS useEffect for automatic fetching customer details."
    );
    console.log("Customer Number SMS: ", props.customerNumber);
    // console.log("EFEGS AGENT ID: ", agentID);
    // console.log("EFEGS X ID: ", xId);

    if (props.customerNumber.length > 0 && props.contactEnded == false) {
      console.log(
        "Setting SMS payload as customer phone is provided and contact has not ended"
      );
      console.log("AGGEWIV: ", props.customerNumber);
      console.log("AGGEWIV: ", phoneValue);
      console.log("JGEIGEN PAYLOAD: ", form.getFieldValue(textArea));

      try {
        form.setFieldsValue({
          Phone: props.customerNumber,
        });
      } catch (error) {
        console.log("SMSERROR: ", error);
      }
    } else {
      console.log("Setting SMS payload as contact has ended");
      try {
        form.setFieldsValue({
          Phone: "",
          message: "",
        });
      } catch (error) {
        console.log("SMSERROR: ", error);
      }

      var event = new CustomEvent("refreshContact", {
        detail: { contactEnded: "" },
      });
      window.dispatchEvent(event);
    }
  }, [props.customerNumber, props.contactEnded, props.contactID]);

  // useEffect(()=>{
  //     console.log(selectedOption);
  // },[selectedOption])

  const onFinishFailed = () => {
    message.error("Please fill required fields.");
  };

  const onClear = () => {
    setObject({});
    setProgLink("");
    setProgValue("");
    setSelectedOption("");
    setIsSpanish(false);
    form.setFieldsValue({
      Phone: "",
      message: "",
    });
  };

  const toEnglish = () => {
    if (objValue != null) {
      setIsSpanish(false);
      setCaseNumber("");
      setProgLink("");
      setProgValue("");
      setSelectedOption(objValue.value);
      form.setFieldsValue({
        message: objValue.value,
      });
    }
  };
  const toSpanish = () => {
    if (objValue != null) {
      setIsSpanish(true);
      setCaseNumber("");
      setProgLink("");
      setProgValue("");
      setSelectedOption(objValue.spanishValue);
      form.setFieldsValue({
        message: objValue.spanishValue,
      });
    }
  };

  const handleMultiple = (e) => {};

  const handleUpdate = (e) => {
    var textAreaMessage = e.target.value;
    var updatedMessage = selectedOption + "\n" + textAreaMessage;
    console.log(updatedMessage);
    setIsSpanish(false);
    form.setFieldsValue({
      message: updatedMessage,
    });
    // setSelectedOption()
  };

  const handleUpdateFCCC = (e) => {
    var textAreaMessage = e.target.value;
    var updatedMessage = selectedOption + "\n" + textAreaMessage;
    console.log(updatedMessage);
    setIsSpanish(false);
    form.setFieldsValue({
      message: updatedMessage,
    });
    // setSelectedOption()
  };

  const handleCaseNumber = (e) => {
    var textAreaMessage = e.target.value;
    setCaseNumber(textAreaMessage);
    var updatedMessage =
      selectedOption.substring(0, selectedOption.indexOf("______________.")) +
      textAreaMessage +
      "." +
      selectedOption.substring(selectedOption.indexOf("_.") + 2);
    console.log(updatedMessage);
    // setIsSpanish(false);
    form.setFieldsValue({
      message: updatedMessage,
    });
    // setSelectedOption()
  };

  const handleFieldOne = (e) => {
    var textAreaMessage = e.target.value;
    setFieldOne(textAreaMessage);
    // var updatedMessage = selectedOption.substring(0, selectedOption.indexOf("______________.")) + textAreaMessage + "." + selectedOption.substring(selectedOption.indexOf("_.") + 2);
    // console.log(updatedMessage);
    // setIsSpanish(false);
    // form.setFieldsValue({
    //   message: updatedMessage,
    // });
    // setSelectedOption()
  };

  const handleFieldTwo = (e) => {
    var textAreaMessage = e.target.value;
    setFieldTwo(textAreaMessage);
    var updatedMessage =
      selectedOption.substring(0, selectedOption.indexOf("______________,")) +
      fieldOne +
      selectedOption.substring(
        selectedOption.indexOf("_,") + 2,
        selectedOption.indexOf("______________.")
      ) +
      textAreaMessage +
      "." +
      selectedOption.substring(selectedOption.indexOf("_.") + 2);
    console.log(updatedMessage);
    // setIsSpanish(false);
    form.setFieldsValue({
      message: updatedMessage,
    });
    // setSelectedOption()
  };

  const handleESTwo = (e) => {
    var textAreaMessage = e.target.value;
    setEsTwo(textAreaMessage);
    var updatedMessage =
      selectedOption.substring(0, selectedOption.indexOf("______________:")) +
      fieldOne +
      selectedOption.substring(
        selectedOption.indexOf("_:") + 2,
        selectedOption.indexOf("(items)")
      ) +
      ": \n" +
      textAreaMessage;
    console.log(updatedMessage);
    // setIsSpanish(false);
    form.setFieldsValue({
      message: updatedMessage,
    });
    // setSelectedOption()
  };

  const handleChange = (e) => {
    console.log("OBJECT IS: ", e);
    setObject(e);
    setIsSpanish(false);
    setSelectedOption(e.value);
    form.setFieldsValue({
      message: e.value,
    });
  };
  const handleTextChange = (e) => {
    form.setFieldsValue({
      message: e,
    });
  };

  const handlePhoneChange = (e) => {
    setPhoneValue(e.target.value);
    form.setFieldsValue({
      Phone: e.target.value,
    });
  };

  const handleSectionChange = (e) => {
    console.log(e);
    setSelectedValue1(e);
  };

  function DataList(props) {
    const [programValue, setProgramValue] = useState();
    function onDropChange(event) {
      const { value } = event.target;
      console.log("ON DROP CHANGE ENGAGED");

      // find the program with the current selected value
      const program = props.programs.find((c) => c.name === value);
      console.log("PROGRAM DETAILS", program);

      if (program !== undefined) {
        // use selected program object here
        setProgramValue(program.value);
        setProgValue(program.value);
        setProgLink(program.link);
        var fullMessage =
          "To inquire about " +
          program.value +
          ", please visit/call " +
          program.link +
          ".";
        form.setFieldsValue({
          message: fullMessage,
        });
        console.log("Program Value: ", program.value);
        console.log("Program Link: ", program.link);
      }
    }

    return (
      <>
        <input
          list="dropdown"
          name="options"
          placeholder={progValue}
          onChange={onDropChange}
        />
        <datalist id="dropdown">
          {props.programs.map((program) => (
            <option
              key={program.value}
              data-value={program.value}
              value={program.name}
            />
          ))}
        </datalist>
      </>
    );
  }

  return (
    <Card style={{ height: 462 }} title="" hoverable>
      <Form
        disabled={isFecthingData}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name={phone}
          label="Phone Number"
          rules={[
            {
              required: true,
              pattern: REGEX.IS_PHONE_NUMBER,
              message: "",
            },
          ]}
        >
          <Input onChange={handlePhoneChange} placeholder="###-###-####" />
        </Form.Item>
        <Form.Item>
          <Segmented
            block
            options={["General", "Interview", "FCCC", "ES", "Custom"]}
            onChange={handleSectionChange}
          />
        </Form.Item>

        <Select
          placeholder="Select or search for a template message"
          options={
            selectedValue1 == "General"
              ? templates
              : selectedValue1 == "Interview"
              ? interviewTemplates
              : selectedValue1 == "FCCC"
              ? fcccTemplates
              : selectedValue1 == "ES"
              ? esTemplates
              : customTemplates
          }
          value={selectedOption}
          //   onChange={selectedValue1 == "Custom" ? null : handleChange}
          onChange={handleChange}
          maxMenuHeight={150}
        />
        {selectedValue1 == "Custom" ? (
          selectedOption ==
            "As a reminder, please provide the following items to DCF within 10 days: " ||
          selectedOption ==
            "Como recordatorio, proporcione los siguientes elementos al DCF dentro de los 10 días:" ? (
            <>
              <label style={{ marginTop: "10px", marginBottom: "5px" }}>
                {selectedOption}
              </label>
              <br />
              <textarea
                style={{ marginBottom: "10px" }}
                onChange={handleUpdate}
                name="postContent"
                rows={4}
                cols={40}
              />
              {/* <Form.Item
                name={textArea}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea disabled={false} rows={5} placeholder={""} />
              </Form.Item> */}

              {/* <Space style={{marginTop:'50px', marginBottom:'50px'}}>
            <label>{selectedOption}</label>
            <input list="urls" id="url2go2" name="options" placeholder=""/>
            <datalist id="urls">
              <option value="option 1"/>
              <option value="option 2"/>
              <option value="option 3"/>
              <option value="option 4"/>
              <option value="option 5"/>
            </datalist>
            </Space> */}

              {/* <TextArea disabled={true} rows={1} style={{width:'90%'}} placeholder={""} /> */}
              {/* <Select
            placeholder="Select"
            options={
              selectedValue1 == "General"
                ? templates
                : selectedValue1 == "Interview"
                ? interviewTemplates
                : selectedValue1 == "FCCC"
                ? fcccTemplates
                : customTemplates
            }
            value={selectedOption}
            onChange={handleChange}
            maxMenuHeight={150}
          //   styles={customStyles}
          //   autosize={false}
          />  */}
            </>
          ) : selectedOption ==
              "As a reminder, please provide the following items to FCCC by: " ||
            selectedOption ==
              "Como recordatorio, proporcione los siguientes elementos a la FCCC a través de: " ? (
            <>
              <label style={{ marginTop: "10px", marginBottom: "5px" }}>
                {selectedOption}
              </label>
              <br />
              <textarea
                style={{ marginBottom: "10px" }}
                onChange={handleUpdateFCCC}
                name="postContent"
                rows={4}
                cols={40}
              />
            </>
          ) : selectedOption ==
              "To inquire about ______________ please visit/call ______________." ||
            selectedOption ==
              "Para preguntar sobre ______________ por favor visite/llame ______________." ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}

              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>To inquire about</label>
                <DataList programs={customDropdown} />
                {/* <input list="urls" id="url2go2" name="options" placeholder=""/>
                <datalist id="urls">
                  <option value="LIEAP"/>
                  <option value="Employment Services"/>
                  <option value="PEBT"/>
                  <option value="Food Assistance"/>
                  <option value="Child Care"/>
                </datalist> */}
                <label>please visit/call</label>
                {/* <input list="urls" id="url2go2" name="options" placeholder=""/> */}
                <TextArea
                  disabled={true}
                  rows={1}
                  style={{ maxWidth: "90%" }}
                  placeholder={progLink}
                />
                {/* <datalist id="urls">
                  <option value="option 1"/>
                  <option value="option 2"/>
                  <option value="option 3"/>
                  <option value="option 4"/>
                  <option value="option 5"/>
                </datalist> */}
              </Space>
            </>
          ) : selectedOption.includes(
              "Your case number for Food Assistance, TANF or Child Care is"
            ) ||
            selectedOption.includes(
              "Su número de caso de Asistencia Alimentaria, TANF o Cuidado Infantil es"
            ) ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________.")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={caseNumber}
                  onChange={handleCaseNumber}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(selectedOption.indexOf("_.") + 2)}
                </label>
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : selectedOption.includes("Your case number for LIEAP is") ||
            selectedOption.includes("Su número de caso de LIEAP es") ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________.")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={caseNumber}
                  onChange={handleCaseNumber}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(selectedOption.indexOf("_.") + 2)}
                </label>
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : selectedOption.includes("Please contact us within 24hrs") ||
            selectedOption.includes(
              "Comuníquese con nosotros dentro de las 24 horas"
            ) ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________.")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={caseNumber}
                  onChange={handleCaseNumber}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(selectedOption.indexOf("_.") + 2)}
                </label>
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : selectedOption.includes(
              "This is a DCF Employment Services reminder"
            ) ||
            selectedOption.includes(
              "Este es un recordatorio de los Servicios"
            ) ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________,")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={fieldOne}
                  onChange={handleFieldOne}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(
                    selectedOption.indexOf("_,") + 2,
                    selectedOption.indexOf("______________.")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={fieldTwo}
                  onChange={handleFieldTwo}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(selectedOption.indexOf("_.") + 2)}
                </label>
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : selectedOption.includes("To Contact your DCF Career Navigator") ||
            selectedOption.includes("Para comunicarse con su orientador") ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________,")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={fieldOne}
                  onChange={handleFieldOne}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(
                    selectedOption.indexOf("_,") + 2,
                    selectedOption.indexOf("______________.")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  value={fieldTwo}
                  onChange={handleFieldTwo}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : selectedOption.includes("As a reminder,") ||
            selectedOption.includes("Como recordatorio,") ? (
            <>
              {/* <label style={{ marginTop: "10px", marginBottom: "10px" }}>
                {selectedOption}
              </label> */}
              <Space style={{ marginTop: "50px", marginBottom: "50px" }}>
                <label>
                  {selectedOption.substring(
                    0,
                    selectedOption.indexOf("______________:")
                  )}
                </label>
                <textarea
                  style={{ marginBottom: "10px" }}
                  placeholder="Enter date"
                  value={fieldOne}
                  onChange={handleFieldOne}
                  name="postContent"
                  rows={1}
                  cols={15}
                />
                <label>
                  {selectedOption.substring(
                    selectedOption.indexOf("_:") + 2,
                    selectedOption.indexOf("(items)")
                  )}
                </label>{" "}
                :
                <br />
                <textarea
                  style={{ marginBottom: "10px" }}
                  placeholder="Enter items"
                  value={esTwo}
                  onChange={handleESTwo}
                  name="postContent"
                  rows={4}
                  cols={40}
                />
                {/* <TextArea disabled={true} rows={1} style={{maxWidth:'90%'}} placeholder={progLink} /> */}
              </Space>
            </>
          ) : (
            <>
              <Space style={{ marginTop: "10px", marginBottom: "10px" }}>
                <label>Please select an option above.</label>
              </Space>
            </>
          )
        ) : (
          <>
            <Form.Item
              name={textArea}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea disabled={true} rows={5} placeholder={""} />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {/* <Button htmlType="button" onClick={onFill}>
                            Templates
                        </Button> */}
            <Button htmlType="button" onClick={onClear}>
              Clear
            </Button>
            {isSpanish ? (
              <>
                <Button htmlType="button" onClick={toEnglish}>
                  Translate to English
                </Button>
              </>
            ) : (
              <>
                <Button htmlType="button" onClick={toSpanish}>
                  Translate to Spanish
                </Button>
              </>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default SMSForm;
