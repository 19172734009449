import { Radio, Row, Form, Checkbox, Input, Anchor, Space, Badge } from "antd";
const { Link } = Anchor;

const TANFWPCOOP = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row style={{ textAlign: "left" }}>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="To be eligible for cash assistance you are required to cooperate with work requirements unless you are determined exempt. Work requirements will be discussed with the Work Program(s) team."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Cash assistance is a life- time limited program where benefits are limited to 24 months (2 years)."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="All applicants applying for TANF are required to complete and return a Self-Assessment form E6 at the time of application."
          />
          <Link style={{ DisplayFormat: "inline-block" }}>
            {
              <a
                style={{ DisplayFormat: "inline-block" }}
                href="http://content.dcf.ks.gov/ees/KEESM/Appendix/E-6 Self-AssessmentFormFillable.pdf"
                target="_blank"
              >
                E-6
              </a>
            }
            <a
              style={{ DisplayFormat: "inline-block" }}
              href="http://content.dcf.ks.gov/ees/KEESM/Appendix/E-6S_Self Assessment05-21.pdf"
              target="_blank"
            >
              E-6S
            </a>
          </Link>
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="This is a mandatory requirement and no exemptions will be given to applicants regarding completing and submitting a Self-Assessment form at the time of application.  If at any time, you do not feel comfortable with the requirement, you may close your case or withdraw your application."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Completed Online Orientation/KWK – KEES updated (New App Only)"
          />
          <Link style={{ DisplayFormat: "inline-block" }}>
            {
              <a
                style={{ DisplayFormat: "inline-block" }}
                href="http://content.dcf.ks.gov/SD/WPOHTML/"
                target="_blank"
              >
                (http://content.dcf.ks.gov/SD/WPOHTML/)
              </a>
            }
          </Link>
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="If you have recently been employed, you may need to apply for unemployment compensation."
          />
        </Space>
      </Row>
      <Form.Item name="Comments1" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Do you have medical coverage?"
        label="Do you have medical coverage?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Type of medical coverage:">
        <Checkbox.Group>
          <Checkbox value="Open medical at Clearing House (CH)">
            Open medical at Clearing House (CH)
          </Checkbox>
          <Checkbox value="Provided Kansas CH information">
            Provided Kansas CH information
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Row>
        <label>
          If you do not have medical coverage you must apply. Please contact the
          KanCare Clearinghouse at 1-800-792-4884
        </label>
      </Row>
      <Form.Item
        name="Are your children (age 5-18) enrolled in school?"
        label="Are your children (age 5-18) enrolled in school? "
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Comments2" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default TANFWPCOOP;
