import { Select, Row, Col, Form, Checkbox, Input, Anchor, Radio } from "antd";
import { useState } from "react";
const { Link } = Anchor;
const { Option } = Select;
const CHILDCARENEEDLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: CHILD CARE NEED LIST – PARENT AND/ OR CHILD CARE NEED
          LIST – CHILD (CC Only)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              defaultValue="Selected Status"
              // style={{ width: 150 }}
              style={{ width: 200 }}
            >
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Are you requesting childcare for work, school, other?"
        label="Are you requesting childcare for work, school, other?"
      >
        <Input style={{ width: "100%" }} placeholder="Comments" />
      </Form.Item>

      <Row>
        <label>
          For{" "}
          {
            <a
              href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm2835.htm"
              target="_blank"
            >
              Post-Secondary education
            </a>
          }{" "}
          education expected to lead to a degree or certificate:
        </label>
      </Row>
      <Form.Item
        name="What is the degree or certificate program you are working on, and when do you expect to complete it??"
        label="What is the degree or certificate program you are working on, and when do you expect to complete it??"
      >
        <Input style={{ width: "100%" }} placeholder="Comments" />
      </Form.Item>
      <Form.Item
        name="Has the participant received 24-month lifetime limit in Child Care for educational purposes?"
        label="Has the participant received 24-month lifetime limit in Child Care for educational purposes?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row gutter={4}>
        <Col span={8}>
          <Form.Item valuePropName="checked" name="Collateral Contact">
            <Checkbox value="Collateral Contact1">Collateral Contact?</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="What is the school term? Begin:" label="Begin:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="What is the school term? End:" label="End:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Are you also employed a minimum of 15 hours per week earning at least the federal minimum wage per hour? (For EDU/CC)"
        label="Are you also employed a minimum of 15 hours per week earning at least the federal minimum wage per hour? (For EDU/CC)"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Work Schedule">
        <Checkbox value="Work Schedule">Work Schedule</Checkbox>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Completed average job outlook at: Occupational Outlook Handbook"
      >
        <Checkbox value="Completed average job outlook at: Occupational Outlook Handbook">
          Completed average job outlook at:{" "}
          {
            <a href="http://www.bls.gov/ooh/a-z-index.htm" target="_blank">
              Occupational Outlook Handbook
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Degree/Certificate Listed">
        <Checkbox value="Degree/Certificate Listed">
          Degree/Certificate Listed
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Degree/Certificate Not Listed">
        <Checkbox value="Degree/Certificate Not Listed">
          Degree/Certificate Not Listed
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Supervisor Approval">
        <Checkbox value="Supervisor Approval">Supervisor Approval</Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Completed ES-1640 (print to Perceptive Content)"
      >
        <Checkbox value="Completed ES-1640 (print to Perceptive Content)">
          Completed{" "}
          {
            <a
              href="http://content.dcf.ks.gov/EES/KEESM/Forms/ES-1640_7_19.pdf"
              target="_blank"
            >
              ES-1640
            </a>
          }{" "}
          (print to Perceptive Content)
        </Checkbox>
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default CHILDCARENEEDLIST;
