import { Row, Col, Form, Checkbox, Input, Select, Radio } from "antd";
import { useState } from "react";
const { Option } = Select;
const EMPLISTINCLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: EMPLOYMENT LIST /INCOME LIST
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <label>
          ***ALL INCOME (EARNED AND UNEARNED) IS CAPTURED IN THIS SECTION***
        </label>
      </Row>
      <Row>
        <label>
          See{" "}
          {
            <a
              href="https://dcfnet.dcf.ks.gov/EES/Documents/Resources/Training
        /TrainingbyTopic/Programs/EES_Guide_to_Budgeting_Income_Earned_and_Unearned.pdf"
              target="_blank"
            >
              Budgeting Income Earned and Unearned
            </a>
          }
        </label>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            valuePropName="checked"
            name="BARI/BASI (printed to Perceptive Content or visually verified)"
          >
            <Checkbox value="BARI/BASI (printed to Perceptive Content or visually verified)">
              BARI/BASI (printed to Perceptive Content or visually verified)
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item valuePropName="checked" name="KDOL checked">
            <Checkbox value="KDOL checked">KDOL checked</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item valuePropName="checked" name="WorkNumber">
            <Checkbox value="WorkNumber">
              {
                <a
                  href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm1322.htm"
                  target="_blank"
                >
                  WorkNumber
                </a>
              }
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            valuePropName="checked"
            name="EATSS visually verified (DO NOT PRINT TO Perceptive Content)"
          >
            <Checkbox value="EATSS visually verified (DO NOT PRINT TO Perceptive Content)">
              EATSS visually verified (DO NOT PRINT TO Perceptive Content)
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            valuePropName="checked"
            name="KAECSES CSS/KPC printed to Perceptive Content/checked"
          >
            <Checkbox value="KAECSES CSS/KPC printed to Perceptive Content/checked">
              KAECSES CSS/KPC printed to Perceptive Content/checked
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item valuePropName="checked" name="eDRS">
            <Checkbox value="eDRS">eDRS</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <label>Checked on ALL household members 18+ at New Application</label>
      </Row>
      <Form.Item name="Comments1" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending For1:" label="Pending For:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Is anyone in your household working or self-employed?"
        label="Is anyone in your household working or self-employed?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <label>
          Who is your current employer/employers?&ensp;
          {
            <a
              href="https://content.dcf.ks.gov/ees/KEESM/Appendix/W-2_daily_business_log_sheet07-16.pdf"
              target="_blank"
            >
              Daily Business Logs
            </a>
          }
        </label>
      </Row>
      <Form.Item
        valuePropName="checked"
        name="Current Employer - Collateral Contact1"
      >
        <Row>
          <Checkbox value="Collateral Contact1?">Collateral Contact?</Checkbox>
        </Row>
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Telephone Number1:" label="Telephone Number:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Business Name1:" label="Business Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        valuePropName="checked"
        name="Current Employer - Collateral Contact1"
      >
        <Row>
          <Checkbox value="Collateral Contact1?">Collateral Contact?</Checkbox>
        </Row>
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Telephone Number2:" label="Telephone Number:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Business Name2:" label="Business Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        valuePropName="checked"
        name="Current Employer - Collateral Contact1"
      >
        <Row>
          <Checkbox value="Collateral Contact3?">Collateral Contact?</Checkbox>
        </Row>
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Telephone Number3:" label="Telephone Number:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Business Name3:" label="Business Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="Comments2" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item valuePropName="checked" name="Terminated in the last 60 days?">
        <Checkbox value="Terminated in the last 60 days?">
          {
            <a
              href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm3540.htm"
              target="_blank"
            >
              Terminated in the last 60 days?
            </a>
          }
        </Checkbox>
      </Form.Item>

      <Form.Item name="Where?" label="Where?">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Reason?" label="Reason?">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item valuePropName="checked" name="Provided 20 job contact form">
        <Checkbox value="Provided 20 job contact form">
          Provided 20 job contact form
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Good Cause-Reason?">
        <Checkbox value="Good Cause-Reason?">
          {
            <a
              href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm3530.htm"
              target="_blank"
            >
              Good Cause
            </a>
          }{" "}
          -Reason
        </Checkbox>
      </Form.Item>
      <Form.Item
        valuePropName="checked"
        name="Current Employer - Collateral Contact4"
      >
        <Row>
          <Checkbox value="Collateral Contact4?">Collateral Contact?</Checkbox>
        </Row>
      </Form.Item>
      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Telephone Number4:" label="Telephone Number:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Business Name4:" label="Business Name:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={4}>
        <label>
          Are any household members receiving Tribal Per Capita Distributions?
        </label>
        <Col span={12}>
          <Form.Item
            name="Are any household members receiving Tribal Per Capita Distributions? - Where?"
            label="Where?"
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="Are any household members receiving Tribal Per Capita Distributions? - Reason?"
            label="Reason?"
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="Is anyone in your household receiving any other income? Social Security/SSI, VA benefits, Worker’s Compensation, Unemployment, Retirement, Severance Pay, or Gift Monies? Do you donate plasma?"
            label="Is anyone in your household receiving any other income? Social Security/SSI, VA benefits, Worker’s Compensation, Unemployment, Retirement, Severance Pay, or Gift Monies? Do you donate plasma?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Comments3:" label="Comments:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="Do you or anyone you are applying for receive Child Support, Alimony? Have you or anyone you are applying for ever received Child Support, Alimony?"
            label="Do you or anyone you are applying for receive Child Support, Alimony? Have you or anyone you are applying for ever received Child Support, Alimony?"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Comments4:" label="Comments:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item name="Comments5:" label="Comments:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Pending For2:" label="Pending For:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default EMPLISTINCLIST;
