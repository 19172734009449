import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;

const INDEMOSCREEN = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row style={{ marginTop: 15 }}>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: INDIVIDUAL DEMOGRAPHICS SCREEN (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
        <Row>
          <label>Individual Demographics List Screen</label>
        </Row>
        <Row>
          <label>MUST COMPLETE IN KEES FOR EACH HH MEMBER</label>
        </Row>
        <Row>
          <label>
            IF THE SSN VERIFICATION FIELD SAYS HUB-SSA, SSA-SVES OR GOOD CAUSE
            DO NOT UPDATE THIS FIELD.
          </label>
        </Row>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default INDEMOSCREEN;
