import { Card, Row, Col, Form, Checkbox, Input, DatePicker } from "antd";
import { useState } from "react";

const ReportingPage = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        valuePropName="checked"
        name="Review and IR / 12 Month Reporting page correctly updated."
      >
        <Checkbox value="Review and IR / 12 Month Reporting page correctly updated.">
          Review and IR / 12 Month Reporting page correctly updated.
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
export default ReportingPage;
