import { Row, Col, Form, Checkbox, Input, Anchor, Select, Radio } from "antd";
const { Link } = Anchor;
const { Option } = Select;
const CS_AuthRep = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: AUTHORIZED REP
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <label>Check Application/Review for Authorized Representative</label>
      <Form.Item
        name="Was the application or review signed by the Authorized Representative?"
        label="Was the application or review signed by the Authorized Representative?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="IF YES- is there an ID in the file for the Authorized Representative?"
        label="IF YES- is there an ID in the file for the Authorized Representative?"
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Link
        href="http://content.dcf.ks.gov/ees/KEESWebHelp/NonMedical-KEESWebHelp/index.htm"
        target="_blank"
        title="KEES USER MANUAL"
      />

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending For:" label="Pending For:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item valuePropName="checked" name="EBT ALTERNATE PAYEE">
        <label>
          Check KEES to ensure Authorized Representative is listed on program
          block End Date All Authorized Representatives who should not be
          included:
        </label>
        <Checkbox
          value="EBT ALTERNATE PAYEE
"
        >
          EBT ALTERNATE PAYEE
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="Is the Authorized Representative also the alternate payee?"
        label="Is the Authorized Representative also the alternate payee?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <label>
        IF YES- send request to EBT mailbox for Authorized Representative to be
        added as an Alternate Payee
      </label>
      <Form.Item
        name="Is the client a resident of an authorized treatment facility?"
        label="Is the client a resident of an authorized treatment facility?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <div>
        IF YES - send request to EBT mailbox for Authorized Representative to be
        added as an Alternate Payee. Include
        <Link
          href="https://content.dcf.ks.gov/EES/KEESM/Forms/ES-3141_alt_payeeForKees.pdf"
          target="_blank"
          title="ES-3141"
        />
      </div>
      <Form.Item valuePropName="checked" name="EBT ALTERNATE PAYEE">
        <label>
          IF SECONDARY CARD IS NEEDED FOR AUTHORIZED REP OR ALTERNATE PAYEE
        </label>
        <Checkbox
          value="E-mail sent to LOCAL EBT e-mailbox
"
        >
          E-mail sent to LOCAL EBT e-mailbox
        </Checkbox>
      </Form.Item>
    </Form>
  );
};
export default CS_AuthRep;
