import { Radio, Row, Form } from "antd";

const EXPEDITEDSTATUS = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
    >
      <Form.Item name="EXPEDITED" label="EXPEDITED">
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row>
        <label>VERIFY CODED CORRECTLY (Case Summary, FA Program Block)</label>
      </Row>
    </Form>
  );
};
export default EXPEDITEDSTATUS;
