import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;
const CITIDENTITYLIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: CITIZENSHIP/ IDENTITY LIST (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="Would you like to register to vote?" label="">
        <Checkbox.Group>
          <Checkbox value="Verified Identification for all adults in the household?">
            Verified Identification for all adults in the household?
          </Checkbox>
          <Checkbox value="Verified Citizenship for TANF/CC?">
            Verified Citizenship for TANF/CC?
          </Checkbox>
          <Checkbox value="LexisNexis Checked/Imaged">
            LexisNexis Checked/Imaged &ensp;&ensp;
            {
              <a
                href="https://dcfnet.dcf.ks.gov/EES/Documents/BPM/VERIFICATION MATRIX.pdf"
                target="_blank"
              >
                Verification Matrix
              </a>
            }
          </Checkbox>
          <Checkbox value="SAVE or Immigration Docs- parolee/refugee/asylum">
            SAVE or Immigration Docs- parolee/refugee/asylum
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default CITIDENTITYLIST;
