import { Card, Row, Col, Form, Radio, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;
const CS_AllPrograms = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: CASE SUMMARY (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Who is in the household?"
        label="Who is in the household?"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Are there others that live in the home that are not included on this application? IF YES, what are their names/relationships. (Add to case if needed to properly determine eligibility)"
        label="Are there others that live in the home that are not included on this application? IF YES, what are their names/relationships. (Add to case if needed to properly determine eligibility)"
      >
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="Are all household members a resident of Kansas?"
        label="Are all household members a resident of Kansas?"
        rules={[
          {
            required: true,
          },
        ]}
        layout="horizontal"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default CS_AllPrograms;
