import { Row, Col, Form, Input, Select, Radio } from "antd";
const { Option } = Select;
const NONCOMPLIANCELIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: NON-COMPLIANCE LIST (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="Felony Drug Conviction after 7/1/2015 (Food Assistance) or 7/1/2013 (TANF)"
        label="Felony Drug Conviction after 7/1/2015 (Food Assistance) or 7/1/2013 (TANF)"
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row>
        <label>
          (Use Generic reason with date of conviction, date of UA, and
          treatment)
        </label>
      </Row>

      <Form.Item name="Cooperating with CSS?" label="Cooperating with CSS?">
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <label>
          If “No” reference KEESM{" "}
          {
            <a
              href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm2165.htm"
              target="_blank"
            >
              2165
            </a>
          }{" "}
          and{" "}
          {
            <a
              href="https://content.dcf.ks.gov/EES/KEESM/Current/keesm2160.htm"
              target="_blank"
            >
              2162
            </a>
          }
        </label>
      </Row>
      <Row>
        <label>
          CC cases, reference{" "}
          {
            <a
              href="https://content.dcf.ks.gov/EES/KEESM/Implem_Memo/2023_07_01_KEESM_Revision_111.docx"
              target="_blank"
            >
              Implementation Memo 7/1/23
            </a>
          }{" "}
        </label>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending for:" label="Pending for:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default NONCOMPLIANCELIST;
