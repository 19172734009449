import { Row, Radio, Form, Input } from "antd";

const ICEBT = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Form.Item
        name="Do you have Kansas Benefits Card?"
        label="Do you have Kansas Benefits Card?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Row>
        <label>
          If No- Check EBTEdge, new cards will not be mailed if an existing
          “acive” status is showing.
        </label>
      </Row>
      <Row>
        <label>FIS Customer Service # 1-800-997-6666</label>
      </Row>
    </Form>
  );
};
export default ICEBT;
