import { Row, Col, Form, Checkbox, Input, Radio } from "antd";

const MDEXPENSELIST = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="horizontal"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Form.Item
        name="Are you OR a member of your household:"
        label="Are you OR a member of your household:"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox.Group>
          <Checkbox value="Disabled">Disabled</Checkbox>
          <Checkbox value="60 or Older">60 or Older</Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="Do you have medical expenses?"
        label="Do you have medical expenses?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox.Group>
          <Checkbox value="One-time expense">One-time expense</Checkbox>
          <Checkbox value="Ongoing expense over $35/ month">
            Ongoing expense over $35/ month
          </Checkbox>
        </Checkbox.Group>
      </Form.Item>
      <Row>
        <label>*Check KMMS for Spenddown if applicable*</label>
      </Row>
      <Form.Item
        name="Does anyone help you pay any of these expenses?"
        label="Does anyone help you pay any of these expenses?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row gutter={4}>
        <Col span={12}>
          <Form.Item name="Comments" label="Comments">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="Pending for:" label="Pending for:">
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default MDEXPENSELIST;
