import { Row, Form, Checkbox, Input, Space, Badge, Radio } from "antd";
import { useState } from "react";

const RightsAndResponsibilities = (props) => {
  // const [textValue, setTextValue] = useState("");
  // const [agentName, setAgentName] = useState("");

  // const onChangeName = (e) => {
  //   setAgentName(e.target.value)
  //   props.form.setFieldsValue({
  //     'Worker Name': e.target.value,
  //   });
  // }

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      // labelCol={{
      //   flex: "200px",
      // }}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        THE FOLLOWING MUST BE VERBALLY CONVEYED TO THE CLIENT DURING THE
        INTERVIEW AND REVIEWED
      </Row>
      <Form.Item
        name="VCC Calls: Did you have an opportunity to listen to the complete
          rights & responsibilities while you were on hold?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes - Questions?</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Row style={{ marginTop: "10px" }}>You have the right to:</Row>
      <Row>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Receive an application, and have your application accepted on the same day that you go to DCF office;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Have an adult who knows your situation apply for you if you cannot get to the DCF office;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Get your Food Assistance benefits within 30 days after you apply if you do qualify for them;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Get Food Assistance benefits within 7 days if you are in immediate need and qualify for faster service;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Not be discriminated against because you are elderly or because of sex, race, color, disability, religious creed, national origin, or political beliefs;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Be told in advance if the DCF office is going to reduce or end your benefits during your certification period because of a change in your situation that you did not report in writing;"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Look at your own case file and a copy of Food Assistance rules; and"
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Have a fair hearing if you don’t think the rules were applied correctly in your case. At a fair hearing, you may explain to a hearing official why you don’t agree with what the DCF office has done."
          />
        </Space>
      </Row>
      <Row style={{ marginTop: "10px" }}>Your Responsibilities:</Row>
      <Row>
        When you apply for Food Assistance benefits, answer all questions
        completely and honestly. Sign your name to certify, under penalty of
        perjury, that all your answers are true.
      </Row>
      <Row>
        <Space direction="vertical">
          <Badge color="#0d0b0b" text="Provide proof that you are eligible." />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Promptly report changes in household circumstances to the DCF office."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Do not put your money or possessions in someone else’s name in order to be able to get Food Assistance benefits."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Do not make changes on any EBT cards or Food Assistance documents."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Do not sell, trade, or give away your Food Assistance benefits, or any EBT cards or Food Assistance documents."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="Use Food Assistance benefits only to buy eligible items."
          />
        </Space>
        <Space direction="vertical">
          <Badge
            color="#0d0b0b"
            text="If you or anyone in your household wins a lottery or gaming prize in a single game that is $4,250 or greater (before taxes or other amounts are withheld), you must report it to DCF within 10 days of the end of the month in which the household received the winnings"
          />
        </Space>
      </Row>
      <Form.Item
        name="Have you read ALL your rights and responsibilities for ALL the
          programs you have applied for today?"
        label="Have you read ALL your rights and responsibilities for ALL the
          programs you have applied for today?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you have any questions about these, that I can help you with?"
        label="Do you have any questions about these, that I can help you with?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="I have read the above Rights and Responsibilities to the client and
          reviewed them with the client."
        label="I have read the above Rights and Responsibilities to the client and
          reviewed them with the client."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="The client was allowed an opportunity to ask questions and these
          questions were answered."
        label="The client was allowed an opportunity to ask questions and these
          questions were answered."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="Worker Name"
        label="Worker Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          id="workerName"
          // value={agentName}
          // onChange={onChangeName}
          // placeholder={agentName || "Enter Worker Name"}
        />
      </Form.Item>
    </Form>
  );
};
export default RightsAndResponsibilities;
