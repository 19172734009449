import { Col, Row, ThemeProvider } from "react-bootstrap";
import CustomerProfile from "../components/CustomerProfile/CustomerProfile";
import SMSForm from "../components/SMSForm";
import Metrics from "../components/Metrics";
import axios from "axios";
import Announcements from "../components/Announcements";
import QueueStatus from "../components/QueueStatus";
import {
  DesktopOutlined,
  CopyOutlined,
  CopyFilled,
  SettingOutlined,
  LineChartOutlined,
  ExpandAltOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Tabs, Alert, Collapse, Button } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import copy from "copy-to-clipboard";
import { Input, Card, Tooltip, Segmented } from "antd";
import ScriptsForm from "../components/ContactForm/Scripts";
import BPMForm from "../components/ContactForm/BPM";
import CustomerForm from "../components/ContactForm/CustomerForm";
import Dashboard from "../components/Callback/Dashboard";
import AdjustCallback from "../components/Callback/AdjustCallback";
import Guide from "../components/Troubleshoot/guide";
import { LAMBDAS } from "../literals";
import Marquee from "react-fast-marquee";
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";

const { Header, Sider, Footer, Content } = Layout;
const idValues = [
  "bf7b33a8-4282-4713-a461-626018c3d0dc",
  "0ea9defc-7300-4742-be6f-c7a64efd5580",
  "b167c35b-6433-496d-b61e-e9a5171ad11c",
  "a80506a8-b51d-4e3e-869d-4032c9bd526c",
  "ae9e7e46-ca84-46da-be8f-1a248506adaa",
  "993eadd7-6ada-40a8-9366-417e8872b60e",
];
const idValuesBanner = [
  "bf7b33a8-4282-4713-a461-626018c3d0dc",
  "bb1935fe-b693-4bf2-af42-4c620b2460d1",
  "2edf1e97-f954-4c81-936c-3b527285be78",
  "9240408e-2362-4064-b42e-99042d97ecc4",
  "b167c35b-6433-496d-b61e-e9a5171ad11c",
  "0ea9defc-7300-4742-be6f-c7a64efd5580",
  "d04580d5-1ef6-4b1c-861e-fa354efddb00",
  "ae9e7e46-ca84-46da-be8f-1a248506adaa",
];
// const idValues = ['2edf1e97-f954-4c81-936c-3b527285be78','a80506a8-b51d-4e3e-869d-4032c9bd526c','ae9e7e46-ca84-46da-be8f-1a248506adaa','993eadd7-6ada-40a8-9366-417e8872b60e']
// const idValues = process.env.KEYS;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Home", "1", <DesktopOutlined />),
  getItem("Queue Status", "2", <LineChartOutlined />),
  getItem("Troubleshooting Guide", "3", <SettingOutlined />),
];

const supervisorItems = [
  getItem("Home", "1", <DesktopOutlined />),
  getItem("Monitor", "2", <DesktopOutlined />),
];

const App = () => {
  const loginStatus = useSelector((state) => state.login.loginValue);
  const [agentName, setAgentName] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("1");
  const [signedIn, setSignedIn] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [contactID, setContactID] = useState("");
  const [agentID, setAgentID] = useState("");
  const [queue, setQueue] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [felonyAnswer, setFelonyAnswer] = useState("");
  const [activeKeyForm, setActiveKeyForm] = useState("1");
  const [activeKeyScript, setActiveKeyScript] = useState("1");
  const [activeKeyAdmin, setActiveKeyAdmin] = useState("1");
  const [activeKeyCallback, setActiveKeyCallback] = useState("1");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [contactEnded, setContactEnded] = useState(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [announcement, setAnnouncement] = useState(
    "There are no Announcement currently posted."
  );
  const [font_size, setFont_size] = useState(15);
  const [form_height, setExtendHeight] = useState(465);
  const operations = (
    <div>
      <Row style={{ margin: 2 }}>
        {/* <Col>Font</Col> */}
        <Col>
          <Tooltip title="Decrease Font size">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setFont_size(font_size - 1);
              }}
            >
              -
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Increase Font size">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setFont_size(font_size + 1);
              }}
            >
              +
            </Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Extend">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                {
                  form_height === 465
                    ? setExtendHeight(945)
                    : setExtendHeight(465);
                }
              }}
            >
              <ExpandAltOutlined />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );

  useEffect(() => {
    console.log("FANSWERFROMTANF changed from App: ", felonyAnswer);
  }, [felonyAnswer]);

  console.log("Login Value is: ", loginStatus);

  const getSecret = async () => {
    const secret_name = "customccp_apikey_prod";
    const client = new SecretsManagerClient({
      region: "us-west-2",
    });

    let response;

    try {
      response = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
        })
      );
    } catch (error) {
      // For a list of exceptions thrown, see
      // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
      throw error;
    }

    const secret = response.SecretString;
    console.log("SECRET: ", secret);
  };

  const onChangeForm = (key) => {
    console.log(key);
    setActiveKeyForm(key);
  };

  const onChangeScript = (key) => {
    console.log(key);
    setActiveKeyScript(key);
  };

  const onChangeAdmin = (key) => {
    console.log(key);
    setActiveKeyAdmin(key);
  };

  const onChangeCallback = (key) => {
    console.log(key);
    setActiveKeyCallback(key);
  };

  const tab_items = [
    {
      key: "1",
      label: "Text to Phone",
      children: (
        <SMSForm
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    {
      key: "2",
      label: "Interview Form",
      children: (
        <CustomerForm
          form_height={form_height}
          font_size={font_size}
          key={customerNumber}
          agentName={agentName}
          caseNumber={caseNumber}
          customerNumber={customerNumber}
          queue={queue}
          felonyAnswer={felonyAnswer}
          contactEnded={contactEnded}
        />
      ),
      // disabled: contactID !== "" ? false : true,
    },
    {
      key: "3",
      label: "Scripts",
      children: <ScriptsForm></ScriptsForm>,
    },
  ];

  let admin_items = [
    {
      key: "1",
      label: "Queue Status",
      children: (
        <QueueStatus
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    {
      key: "2",
      label: "Adjust Capacity",
      children: (
        <Metrics
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
  ];

  const script_items = [
    {
      key: "1",
      label: "Queue Status",
      children: (
        <QueueStatus
          customerNumber={customerNumber}
          contactEnded={contactEnded}
          contactID={contactID}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "HSA Greeter Template",
    //   children: (
    //     <ScriptsForm/>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: "BPM SCRIPTS AND DOCUMENTATION TEMPLATE",
    //   children: <BPMForm/>,
    //   // disabled: contactID !== "" ? false : true,
    // },
  ];

  const setIndex = (e) => {
    if (e.key == "1") {
      setSelectedIndex("1");
    } else if (e.key == "2") {
      setSelectedIndex("2");
    } else if (e.key == "3") {
      setSelectedIndex("3");
    }
  };

  window.addEventListener("loggedIn", function () {
    console.log("fooLoggedIn!");
    setSignedIn(true);
  });

  window.addEventListener("loggedOut", function () {
    console.log("fooLoggedOut!");
    setSignedIn(false);
  });

  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      setAgentID(agentID);
      var agentNameVal = e.detail.agentName;
      setAgentName(agentNameVal);
    },
    false
  );

  useEffect(() => {
    if (!isFirstLoad || isRefreshing) {
      axios({
        method: "POST",
        baseURL: LAMBDAS.CURRENT_ANNOUNCEMENT,
        data: {},
      })
        .then((response) => {
          let statewideAnnouncement = response.data.statewide;
          console.log("STATEWIDE ANNOUNCEMENT: ", statewideAnnouncement);
          console.log(
            "FETCHING STATEWIDE ANNOUNCEMENT: ",
            statewideAnnouncement
          );
          setAnnouncement(statewideAnnouncement);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsFirstLoad(true);
      setIsRefreshing(false);
    }
  }, [isFirstLoad, isRefreshing]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      console.log("Running timeout fetch every minute ");
      axios({
        method: "POST",
        baseURL: LAMBDAS.CURRENT_ANNOUNCEMENT,
        data: {},
      })
        .then((response) => {
          let statewideAnnouncement = response.data.statewide;
          console.log("STATEWIDE ANNOUNCEMENT: ", statewideAnnouncement);
          console.log(
            "FETCHING STATEWIDE ANNOUNCEMENT: ",
            statewideAnnouncement
          );
          setAnnouncement(statewideAnnouncement);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 60000);

    return () => clearInterval(intervalID);
  }, []);

  useEffect(() => {
    if (!isFirstLoad || isRefreshing) {
      axios({
        method: "POST",
        baseURL: LAMBDAS.CURRENT_ANNOUNCEMENT,
        data: {},
      })
        .then((response) => {
          let statewideAnnouncement = response.data.statewide;
          console.log("STATEWIDE ANNOUNCEMENT: ", statewideAnnouncement);
          console.log(
            "FETCHING STATEWIDE ANNOUNCEMENT: ",
            statewideAnnouncement
          );
          setAnnouncement(statewideAnnouncement);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsFirstLoad(true);
      setIsRefreshing(false);
    }
  }, [isFirstLoad, isRefreshing]);

  useEffect(() => {
    console.log("NAME FROM USEEFFECT: ", agentName);
  }, [agentName]);

  window.addEventListener(
    "contactEvent",
    async function (e) {
      console.log("Customer Number: ", e.detail.customerNo);
      var customerNumber = e.detail.customerNo;
      var contactID = e.detail.contactID;
      setCustomerNumber(customerNumber);
      setContactID(contactID);
    },
    false
  );

  window.addEventListener(
    "attributeEvent",
    async function (e) {
      console.log("Attributes: ", e.detail.attributes);
      console.log("Before loading");
      // var isAuthenticated = e.detail.attributes.authenticated.value || '';
      var caseNumber = "";
      if (e.detail.attributes.caseNumber != null) {
        console.log("Case Number is not null");
        caseNumber = e.detail.attributes.caseNumber.value;
        console.log("Case Number: ", caseNumber);
      }

      var coloredQueue = "";
      if (e.detail.attributes.finalQueue != null) {
        console.log("Colored Queue is Not Null");
        coloredQueue = e.detail.attributes.finalQueue.value;
        console.log("coloredQueue: ", coloredQueue);
      }

      var felonyTANF = "";
      if (e.detail.attributes.FelonyAnswer != null) {
        console.log("FELONY ANSWER IS NOT NULL");
        felonyTANF = e.detail.attributes.FelonyAnswer.value;
        console.log("Felony answer: ", felonyTANF);
      }

      console.log("After loading");

      // console.log("IS AUTHENTICATED: ", isAuthenticated)

      // setIsAuthenticated(isAuthenticated);
      setQueue(coloredQueue);
      setCaseNumber(caseNumber);
      setFelonyAnswer(felonyTANF);
      if (coloredQueue === "Green" || coloredQueue === "Red") {
        console.log("Setting active queue to interview forms");
        setActiveKeyForm("2");
        setActiveKeyScript("4");
        setActiveKeyAdmin("4");
      }
    },
    false
  );

  // 2edf1e97-f954-4c81-936c-3b527285be78

  window.addEventListener(
    "contactEnded",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      console.log("contact ended in app");
      setContactEnded(true);
      setCustomerNumber("");
      setContactID("");
      setCopiedToClipboard(false);
      setIsAuthenticated(false);
      setActiveKeyForm("1");
      setActiveKeyScript("1");
      setActiveKeyAdmin("1");
      setQueue("");
      setFelonyAnswer("");
    },
    false
  );

  window.addEventListener(
    "refreshContact",
    async function (e) {
      // console.log("Contact Ended: ", e.detail.contactEnded);
      setContactEnded(false);
    },
    false
  );

  useEffect(() => {
    getSecret();
    console.log(window);
    window["onload"]();
  }, []);

  useEffect(() => {
    console.log("UGHDN WindowSignedIn: ", window.isSignedIn);
    if (window["isSignedIn"] == "Signed In") {
      console.log("WIIIINDDOWW RESULT IS SIGNED IN");
    } else {
      console.log("WIIIINDDOWW RESULT IS SIGNED OUT");
    }
  }, [window["isSignedIn"]]);

  useEffect(() => {
    console.log(queue);
  }, [queue]);

  const sizes = ["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"];

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {/* style={{backgroundColor: '#26303B'}} */}
      <Sider
        trigger={
          <div style={{ backgroundColor: "#26303B" }}>
            {collapsed ? ">" : "<"}
          </div>
        }
        style={{ backgroundColor: "#26303B" }}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="center-content">
          <a href="index.html">
            <i className="lilly-icon lilly-icon-logo"></i>
          </a>
        </div>
        <div className="center-content">
          {collapsed === false && (
            <div className="header-title">Amazon Connect</div>
          )}
        </div>

        <Menu
          theme="dark"
          style={{ backgroundColor: "#26303B" }}
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onClick={setIndex}
        />
      </Sider>

      <Layout className="site-layout">
        <div
          className="site-layout"
          style={{ display: selectedIndex === "1" ? "block" : "none" }}
        >
          <Header
            className="site-layout-header-background"
            style={{
              padding: 0,
            }}
          />
          <Content
            style={{
              margin: "7px 7px",
            }}
          >
            <div className="site-layout-background">
              <Alert
                banner
                type="info"
                message={
                  <Marquee pauseOnHover gradient={false}>
                    {announcement}
                  </Marquee>
                }
              />
              <ThemeProvider breakpoints={sizes}>
                <Row
                  justify="space-evenly"
                  style={{ overflow: "auto", minHeight: "50vh" }}
                >
                  <Col>
                    {/* <div className="ccpcontainer" id="ccp-container"></div> */}
                    {/* <div style={{ height: '50%' }} id="ccp-container"></div> */}
                    <Collapse
                      // style={{ backgroundColor: "#dddddd" }}
                      defaultActiveKey={["1"]}
                      bordered={false}
                      showArrow={false}
                      items={[
                        {
                          key: "1",
                          label: "Contact Panel",
                          children: (
                            <div
                              id="ccp-container"
                              style={{ height: 460 }}
                              // className="ccpcontainer"
                            ></div>
                          ),
                        },
                      ]}
                    />

                    {queue == "Red" ? (
                      <>
                        <div className="contact-id-red">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : queue == "Green" ? (
                      <>
                        <div className="contact-id-green">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : queue == "Blue" ? (
                      <>
                        <div className="contact-id-blue">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : queue == "IR" ? (
                      <>
                        <div className="contact-id-ir">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : queue == "Purple" ? (
                      <>
                        <div className="contact-id-purple">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="contact-id">
                          Contact ID: {contactID}{" "}
                          {copiedToClipboard ? (
                            <>
                              <CopyFilled />
                            </>
                          ) : (
                            <>
                              <CopyOutlined
                                onClick={() => {
                                  if (contactID.length > 0) {
                                    navigator.clipboard.writeText(contactID);
                                    setCopiedToClipboard(true);
                                  }
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div>
                      {signedIn ? (
                        <CustomerProfile
                          customerNumber={customerNumber}
                          contactEnded={contactEnded}
                          contactID={contactID}
                        />
                      ) : (
                        <>
                          <Card title="Please sign into Amazon Connect"></Card>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col>
                    {signedIn ? (
                      <>
                        <Tabs
                          tabBarExtraContent={
                            activeKeyForm === "2" ? operations : ""
                          }
                          defaultActiveKey={[1]}
                          activeKey={activeKeyForm}
                          items={tab_items}
                          onChange={onChangeForm}
                          tabBarStyle={{
                            // backgroundColor: "#4a4a4a",12-16-2024
                            paddingLeft: 30,
                            backgroundColor: "#dddddd",
                            borderRadius: 7,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Card title="Please sign into Amazon Connect"></Card>
                      </>
                    )}
                  </Col>
                </Row>

                <br />
              </ThemeProvider>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            EES-CCP ©2022
          </Footer>
        </div>
        <div
          className="site-layout"
          style={{ display: selectedIndex === "2" ? "block" : "none" }}
        >
          <ThemeProvider breakpoints={sizes}>
                <Row
                  justify="space-evenly"
                  style={{ overflow: "auto", minHeight: "50vh" }}
                >
                  {signedIn ? 
                  <Dashboard
              customerNumber={customerNumber}
              contactEnded={contactEnded}
              contactID={contactID}
            />
            :

<Card
                        title="Please sign into Amazon Connect"
                      ></Card>
                  }
                  <Col>
                  {signedIn ? (
            <Tabs
            defaultActiveKey={[1]}
            activeKey={activeKeyAdmin}
            items={[
              
                {
                  key: "1",
                  label: "Queue Status",
                  children: (
                    <QueueStatus
                      customerNumber={customerNumber}
                      contactEnded={contactEnded}
                      contactID={contactID}
                    />
                  ),
                },
                {
                  key: "2",
                  label: "Adjust Capacity",
                  children: (
                    <Metrics
                      customerNumber={customerNumber}
                      contactEnded={contactEnded}
                      contactID={contactID}
                    />
                  ),
                },
                idValuesBanner.includes(agentID) ?
                  {
                    key: "3",
                    label: "Post Announcement",
                    children: (
                      <Announcements
                        customerNumber={customerNumber}
                        contactEnded={contactEnded}
                        contactID={contactID}
                        isSupervisor={true}
                      />
                    ),
                  }
                : <></>

            ]
          }
            onChange={onChangeAdmin}
            tabBarStyle={{backgroundColor:'#ffffff',paddingLeft:30}}
            
          />
          ) : (
            <Card
                        title="Please sign into Amazon Connect"
                      ></Card>
          )}


                  </Col>
                  <Col>
                  {signedIn ? (
            
            <Tabs
            defaultActiveKey={[1]}
            activeKey={activeKeyCallback}
            items={[
              
                {
                  key: "1",
                  label: "Adjust Callback",
                  children: (
                    <AdjustCallback
                      customerNumber={customerNumber}
                      contactEnded={contactEnded}
                      contactID={contactID}
                    />
                  ),
                }
            ]
          }
            onChange={onChangeCallback}
            tabBarStyle={{backgroundColor:'#ffffff',paddingLeft:30}}
            
          />
            
          ) : (
            <Card
            title="Please sign into Amazon Connect"
          ></Card>
          )}
                  

                  </Col>

          </Row>
          

          <br />
          </ThemeProvider>

          
        </div>
        <div
          className="site-layout"
          style={{ display: selectedIndex === "3" ? "block" : "none" }}
        >
          <Guide />
        </div>
      </Layout>
    </Layout>
  );
};

export default App;
