import { Select, Row, Col, Form, Checkbox, Input, Radio } from "antd";

const { Option } = Select;
const DVSASS = (props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      //   wrapperCol={{
      //     flex: 1,
      //   }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: DOMESTIC VIOLENCE, SEXUAL ASSAULT, OR STALKING SCREENING
          (REQUIRED)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <label>
          Because domestic violence has so many effects on a person and
          families, I am going to ask you a series of standard questions to
          offer available services to anyone who is concerned about violence in
          their home.
        </label>
      </Row>
      <Form.Item
        name="Are you or your children currently being threatened, hurt, or harmed by someone?"
        label="Are you or your children currently being threatened, hurt, or harmed by someone?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you need assistance now because working, looking for a job, or going to school may put you or your children in danger of physical, emotional or sexual abuse or stalking?"
        label="Do you need assistance now because working, looking for a job, or going to school may put you or your children in danger of physical, emotional or sexual abuse or stalking?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Do you need assistance now because you are having a hard time dealing with past abuse?"
        label="Do you need assistance now because you are having a hard time dealing with past abuse?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Have you spent time trying to deal with or escape from someone who was threatening, hurting, or harming you or your children?"
        label="Have you spent time trying to deal with or escape from someone who was threatening, hurting, or harming you or your children?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="Are you interested in speaking with someone about this?"
        label="Are you interested in speaking with someone about this?"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row>
        <label>
          Note: A DV/SA referral is not needed if the client does not want to
          speak with someone and declines DV/SA services. Document the reason
          for not completing a referral.
        </label>
      </Row>
      <label>IF YES:</label>
      <Form.Item
        valuePropName="checked"
        name="EES-4416 completed and sent to DCF.EESDVSA@ks.gov"
      >
        <Checkbox value="EES-4416 completed and sent to DCF.EESDVSA@ks.gov">
          EES-4416 completed and sent to{" "}
          {
            <a href="mailto:DCF.EESDVSA@ks.gov" target="_blank">
              DCF.EESDVSA@ks.gov
            </a>
          }
        </Checkbox>
      </Form.Item>
      <Form.Item valuePropName="checked" name="Print to Perceptive Content">
        <Checkbox value="Print to Perceptive Content">
          Print to Perceptive Content
        </Checkbox>
      </Form.Item>
      <label>(Do not include any DVSA information on the NOA) </label>
      <label>Client referred to: Search Provider by County</label>
      <label>
        (Ask client how they want to receive this contact information. Client
        must be made aware that they are responsible for getting in touch with
        the DV/SA provider)
      </label>
      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default DVSASS;
