import { Input, Card, Tooltip, Alert, Segmented } from "antd";
import { useState, useEffect } from "react";
import useUpdateFetch from "../../hooks/useUpdateFetch";
import useStateWithValidation from "../../hooks/useStateWithValidation";
import { REGEX, LAMBDAS } from "../../literals";
import {
  PhoneOutlined,
  UserOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import { Row } from "react-bootstrap";
import TopLevelAssociateTable from "./components/TopLevelAssociateTable";
import TopLevelCaseTable from "./components/TopLevelCaseTable";
import {
  ConvertToPrettyDate,
  DistinctByProperties,
  GroupByProperties,
} from "../../utils";
//import useUpdateLogger from '../../hooks/useUpdateLogger';

const { Search } = Input;
const SEGMENT_OPTIONS = {
  PROFILE: "profiles",
  CASE: "cases",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function AssociateToCase(profiles) {
  if (profiles === null) {
    return profiles;
  }
  const casesWithAssociate = profiles.reduce((cases, profile) => {
    profile.cases?.forEach((c) => cases.push({ profile: profile, ...c }));
    return cases;
  }, []);
  const groupedCased = GroupByProperties(casesWithAssociate, (c) => [
    c.caseNumber,
    c.status,
    c.programCode,
    c.beginDate,
    c.endDate,
    c.zipCode,
  ]);
  const cases = [];
  groupedCased.forEach((caseGroup, keys) => {
    const [caseNumber, status, programCode, beginDate, endDate, zipCode] =
      JSON.parse(keys);
    const profilesForCase = [];
    caseGroup.forEach((c) => {
      profilesForCase.push({
        key: c.profile.key,
        firstName: c.profile.firstName,
        lastName: c.profile.lastName,
        phoneAssociation: c.profile.phoneAssociation,
        ssn: c.profile.ssn,
        dob: c.profile.dob,
        zipCode: c.profile.zipCode,
      });
    });
    cases.push({
      profiles: DistinctByProperties(profilesForCase, (profile) => [
        profile.ssn,
        profile.dob,
        profile.firstName,
        profile.lastName,
      ]),
      caseNumber: caseNumber,
      programCode: programCode,
      status: status,
      beginDate: beginDate,
      endDate: endDate,
      zipCode: zipCode,
    });
  });

  return cases.map((c, index) => {
    return { key: index, ...c };
  });
}
function AddKeyAndPrettifyDates(profiles) {
  if (profiles === undefined || profiles === null) {
    return null;
  }
  return profiles.map((a, i) => {
    a.key = i;
    a.dob = ConvertToPrettyDate(a.dob);
    a.phoneAssociation =
      a.phoneAssociation === null ||
      a.phoneAssociation === undefined ||
      a.phoneAssociation === []
        ? ["Unknown"]
        : a.phoneAssociation;
    a.cases =
      a.cases === null || a.cases === undefined
        ? []
        : a.cases.map((c, j) => {
            c.key = j;
            // console.log("A Begin Date");
            // console.log(a.beginDate);
            // console.log("A End Date");
            // console.log(a.endDate);

            // console.log("C Begin Date");
            // console.log(c.beginDate);
            // console.log("C End Date");
            // console.log(c.endDate);

            c.beginDate = ConvertToPrettyDate(c.beginDate);
            c.endDate = ConvertToPrettyDate(c.endDate);
            // c.beginDate = c.beginDate &&  ConvertToPrettyDate(a.beginDate);
            // c.endDate = c.endDate && ConvertToPrettyDate(a.endDate);
            return c;
          });
    return a;
  });
}

const CustomerProfile = (props) => {
  function IsBadRequest(error) {
    return false;
  }
  function ServerIsDown(error) {
    return false;
  }
  const [phoneNumber, setPhoneNumber, isValidPhoneNumber] =
    useStateWithValidation(
      (number) =>
        number === "" || number.match(REGEX.IS_PHONE_NUMBER)?.length > 0,
      ""
    );
  const [payLoad, setpayLoad] = useState(null);
  const [contactID, setContactID] = useState("");
  const [agentID, setAgentID] = useState("");
  const [attributes, setAttributes] = useState({});
  const [xId, setXId] = useState("");
  const [viewToggle, setViewToggle] = useState(SEGMENT_OPTIONS.PROFILE);
  const {
    data: repsonse,
    isFecthingData,
    error,
    request,
  } = useUpdateFetch(
    LAMBDAS.CUSTOMER_PROFILES,
    payLoad,
    "POST",
    "application/json",
    null,
    agentID
  );
  const profiles = AddKeyAndPrettifyDates(repsonse);
  const cases = AssociateToCase(profiles);

  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      var xID = e.detail.xID;

      setAgentID(agentID);
      setXId(xID);
    },
    false
  );

  window.addEventListener(
    "attributeEvent",
    async function (e) {
      var attributes = e.detail.attributes;
      setAttributes(attributes);
      console.log("VJUEQ: ", attributes);
    },
    false
  );

  useEffect(() => {
    console.log("Inside useEffect for automatic fetching customer details.");

    if (props.customerNumber.length > 0 && props.contactEnded == false) {
      console.log(
        "Setting payload as customer phone is provided and contact has not ended"
      );
      setPhoneNumber(props.customerNumber);
      console.log("PhoneNumber: ", phoneNumber);

      setpayLoad(
        JSON.stringify({
          phoneNumber: props.customerNumber.replace(REGEX.IS_NUMERIC_ONLY, ""),
          agentID: agentID,
          xID: xId,
          environment: "uat",
        })
      );
      console.log("PAYLOAD IN CUST PROFILE: ", payLoad);
    } else {
      console.log("Setting payload as contact has ended");
      setPhoneNumber("");
      console.log("PhoneNumber: ", phoneNumber);

      setpayLoad(
        JSON.stringify({
          phoneNumber: "".replace(REGEX.IS_NUMERIC_ONLY, ""),
          agentID: agentID,
          xID: xId,
          environment: "uat",
        })
      );
      console.log("PAYLOAD IN CUST PROFILE: ", payLoad);
      var event = new CustomEvent("refreshContact", {
        detail: { contactEnded: "" },
      });
      window.dispatchEvent(event);
    }
  }, [props.customerNumber, props.contactEnded]);

  function onPhoneNumberSearch(number) {
    console.log("Searching Now");
    if (isValidPhoneNumber === false || number.length == 0) {
      return;
    }
    setpayLoad(
      JSON.stringify({
        phoneNumber: number.replace(REGEX.IS_NUMERIC_ONLY, ""),
        agentID: agentID,
        xID: xId,
        environment: "uat",
      })
    );
  }
  function ToggleTableView(choice) {
    switch (choice) {
      case SEGMENT_OPTIONS.PROFILE:
        return (
          <TopLevelAssociateTable
            loading={isFecthingData === true}
            profiles={profiles ?? []}
            pagination={true}
          />
        );
      case SEGMENT_OPTIONS.CASE:
        return (
          <TopLevelCaseTable
            loading={isFecthingData === true}
            cases={cases ?? []}
            pagination={true}
          />
        );
      default:
        return (
          <TopLevelCaseTable
            loading={isFecthingData === true}
            cases={cases ?? []}
            pagination={true}
          />
        );
    }
  }

  return (
    <Card
      style={{ height: 461 }}
      title="Customer Profile"
      hoverable
      extra={
        <Tooltip
          color={isValidPhoneNumber === true ? "" : "yellow"}
          placement="bottom"
          title={
            isValidPhoneNumber === true
              ? "Retrieves profiles with provided home/cell number."
              : "Provided number is not in a valid format."
          }
        >
          <Search
            disabled={isFecthingData === true}
            loading={isFecthingData === true}
            status={isValidPhoneNumber === true ? "" : "warning"}
            onSearch={onPhoneNumberSearch}
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            placeholder="###-###-####"
            suffix={
              <PhoneOutlined
                style={{
                  fontSize: 16,
                  color: "#1890ff",
                }}
              />
            }
          />
        </Tooltip>
      }
    >
      <Row>
        {/* <div className="center" style={{ color: "#B4B4B4" }}>
        {request.data == null
                ? `Phone number ${phoneNumber} is not registered` 
                : ((profiles === null || profiles === undefined)
                    ? ``
                    : `Phone number ${JSON.parse(request.data)?.phoneNumber} registered ${viewToggle}`)}
        </div> */}

        {/* <div className='center'style={{color: "#B4B4B4"}}>
              {IsBadRequest(error) === true 
                ? `Phone number ${phoneNumber} is not registered` 
                : ((profiles === null || profiles === undefined)
                    ? ``
                    : `Phone number ${JSON.parse(request.data)?.phoneNumber} registered ${viewToggle}`)}
        </div> */}

        {ServerIsDown(error) === true ? (
          <Alert message="Server Is Unavilable" type="warning" showIcon />
        ) : (
          <div className="center" style={{ color: "#B4B4B4" }}>
            {IsBadRequest(error) === true
              ? `Phone number ${phoneNumber} is not registered`
              : profiles === null || profiles === undefined
              ? `No profiles`
              : `Registered ${viewToggle} for ${
                  JSON.parse(request.data)?.phoneNumber
                }`}
          </div>
        )}
      </Row>
      <br />
      <Row>
        <Segmented
          block
          defaultValue={viewToggle}
          onChange={(e) => setViewToggle(e)}
          options={[
            {
              label: (
                <div style={{ padding: 4 }}>
                  <UserOutlined />
                  <div>Profiles View</div>
                </div>
              ),
              value: SEGMENT_OPTIONS.PROFILE,
            },
            {
              label: (
                <div style={{ padding: 4 }}>
                  <ContainerOutlined />
                  <div>Case View</div>
                </div>
              ),
              value: SEGMENT_OPTIONS.CASE,
            },
          ]}
        />
      </Row>
      <Row>{ToggleTableView(viewToggle)}</Row>
    </Card>
  );
};

export default CustomerProfile;
