import { Table, Button, Form, Input, message, Space, Card, Segmented } from "antd";
import { useEffect, useState, useRef } from "react";
import useTextFetch from "../hooks/useTextFetch";
import useUpdateEffect from "../hooks/useUpdateEffect";
import axios from 'axios';
import { REGEX, LAMBDAS } from "../literals";
import ConvertToFormUrlEncoded from "../utils";
import Modal from "react-modal";
import useUpdateLogger from "../hooks/useUpdateLogger";
import SelectBox from "devextreme-react/select-box";
import List from "devextreme-react/list";
import { templates } from "../assets/textTemplates";
import { interviewTemplates } from "../assets/interviewTemplates";
import { fcccTemplates } from "../assets/fcccTemplates";
import { customTemplates } from "../assets/customTemplates";
import { customDropdown } from "../assets/customDropdown";
import { queueDropdown } from "../assets/queueDropdown";
import { capacityDropdown } from "../assets/capacityDropdown";
import SelectSearch from "react-select-search";
import Select from "react-select";
import useAdjustFetch from "../hooks/useAdjustFetch";
const { TextArea } = Input;

const options = [
  {
    value:
      "The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
    label:
      "Apply: The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
  },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
  { value: "vanilla", label: "Chocholate" },
];

function ItemTemplate(data) {
  return <div>{data.Content}</div>;
}

var reg = /^\d+$/;
const textArea = "amount";
const queueArea = "queue";
const capacityArea = "capacityType";

const customStyles = {
  content: {
    width: "50%",
    height: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const Metrics = (props) => {
  const [textValue, setTextValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("General");
  const [selectedOption, setSelectedOption] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [queueValue, setQueueValue] = useState("");
  const [contactID, setContactID] = useState("");
  const [routingProfile, setRoutingProfile] = useState("");
  const [agentID, setAgentID] = useState("");
  const [xId, setXId] = useState("");
  const [searchMode, setSearchMode] = useState("contains");
  const [form] = Form.useForm();
  const [payLoad, setpayLoad] = useState(null);
  const { data, isFecthingData, error } = useAdjustFetch(
    LAMBDAS.ADJUST_QUEUE,
    payLoad,
    "POST",
    "application/json"
  );
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSpanish, setIsSpanish] = useState(false);
  const [objValue, setObject] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [progValue, setProgValue] = useState("select queue");
  const [capacityTypeVal, setCapacityTypeVal] = useState("temporary");
  const [programValue, setProgramValue] = useState();
  const [progLink, setProgLink] = useState("");
  const [caseNumber, setCaseNumber] = useState("")
  const [dataSource, setDataSource] = useState([{
    red: 0,
    green: 0,
    blue: 0,
    ir: 0,
    purple: 0,
    hsa: 0,
    lieap: 0
  }])

  // const dataSource = [
  //   {
  //     red: 24,
  //     green: 13,
  //     blue: 32,
  //     ir: 24,
  //     purple: 10,
  //     hsa: 24
  //   }
  // ];



  const columns = [
    {
      title: 'Red Queue',
      dataIndex: 'red',
      key: 'red',
    },
    {
      title: 'Green Queue',
      dataIndex: 'green',
      key: 'green',
    },
    {
      title: 'Blue Queue',
      dataIndex: 'blue',
      key: 'blue',
    },
    {
      title: 'IR Queue',
      dataIndex: 'ir',
      key: 'ir',
    },
    {
      title: 'Purple Queue',
      dataIndex: 'purple',
      key: 'purple',
    },
    {
      title: 'HSA Queue',
      dataIndex: 'hsa',
      key: 'hsa',
    },
    {
      title: 'LIEAP Queue',
      dataIndex: 'lieap',
      key: 'lieap',
    },
  ];

  const validateAmount = (amount) => {
    let result = false;
    if (containsOnlyDigits(amount) && parseInt(amount) >= 0 && amount.length < 5) {
      result = true;
    }
    return result;
  }

  function containsOnlyDigits(str) {
    return /^\d+$/.test(str);
  }

  const onFinish = () => {
    var newPayload = {
      amount: form.getFieldValue(textArea),
      queue: form.getFieldValue(queueArea),
      capacityType: form.getFieldValue(capacityArea),
      closeQueues: false,
      agentID: agentID,
      xID: xId,
      requestType: "queue"
    };

    console.log("NEW PAYLOAD: ", newPayload)
    if (validateAmount(amountValue) && queueValue.length) {
      console.log("AMT AREA PAYLOAD: ", amountValue);
      console.log("QUE Area PAYLOAD: ", queueValue);
      //setpayLoad(ConvertToFormUrlEncoded(form.getFieldsValue()));
      var newPayload = {
        amount: form.getFieldValue(textArea),
        queue: form.getFieldValue(queueArea),
        capacityType: form.getFieldValue(capacityArea),
        closeQueues: false,
        agentID: agentID,
        xID: xId,
        requestType: "queue"
      };
      setpayLoad(newPayload);
      setAmountValue("")
      setQueueValue("")
      setProgValue("select queue")
      setProgramValue("")
      setCapacityTypeVal("temporary")
      form.setFieldsValue({
        amount: "",
        queue: "",
        capacityType: "temporary",
        closeQueues: false
      });
      console.log("FORM VALUE: ", form);
      console.log("New Payload: ", newPayload);
      setIsRefreshing(true)
    }
  };

  const onCloseQueues = () => {

    console.log("AMT AREA PAYLOAD: ", amountValue);
    console.log("QUE Area PAYLOAD: ", queueValue);
    // setpayLoad(ConvertToFormUrlEncoded(form.getFieldsValue()));
    var newPayload = {
      amount: form.getFieldValue(textArea),
      queue: form.getFieldValue(queueArea),
      capacityType: "temporary",
      closeQueues: true,
      agentID: agentID,
      xID: xId,
      requestType: "queue"
    };
    setpayLoad(newPayload);
    setAmountValue("")
    setQueueValue("")
    setProgValue("select queue")
    setProgramValue("")
    setCapacityTypeVal("temporary")
    form.setFieldsValue({
      amount: "",
      queue: "",
      capacityType: "temporary",
      closeQueues: false
    });
    console.log("FORM VALUE: ", form);
    console.log("New Payload: ", newPayload);
    setIsRefreshing(true)
  };

  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      var xID = e.detail.xID;
      var routingProfile = e.detail.routingProfile;

      setAgentID(agentID);
      setXId(xID);
      setRoutingProfile(routingProfile);
    },
    false
  );

  useUpdateEffect(() => {
    console.log("Use Update Effect");
    // eslint-disable-next-line no-sequences
    if (amountValue.length > 0 && queueValue.length > 0) {
      console.log("Inside USE UPDATE EFFECT for sending amount");
      console.log("Data: ", data);
      console.log("Error: ", error);

      switch ((data === null, error === null)) {
        case (false, true):
          console.log("INSIDE FALSE TRUE");
          console.log("BGEBGEGE");
          // amount.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            amount: "",
            queue: "",
            capacityType: "temporary",
            closeQueues: false
          });
          break;
        case (true, false):
          console.log("INSIDE TRUE FALSE");
          // amount.error(`Text failed to send to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            amount: "",
            queue: "",
            capacityType: "temporary",
            closeQueues: false
          });
          break;
        default:
          // amount.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            amount: "",
            queue: "",
            capacityType: "temporary",
            closeQueues: false
          });
          break;
      }
    }
  }, [error, data]);

  useEffect(() => {
    if (!isFirstLoad || isRefreshing) {
      axios({
        method: 'POST',
        baseURL: LAMBDAS.CURRENT_METRICS,
        data: {},
      }).then((response) => {
        let finalData = []
        finalData.push(response.data)
        console.log("RESPONSE DATA CURRENT METRICS: ", finalData)
        setDataSource(finalData)

      }).catch((error) => {
        console.log(error);
      });
      setIsFirstLoad(true)
      setIsRefreshing(false)
    }

  }, [isFirstLoad, isRefreshing])

  useEffect(() => {
    console.log(
      "Inside ADJUST METRIC useEffect."
    );

    if (props.customerNumber.length > 0 && props.contactEnded == false) {
      console.log(
        "Setting SMS payload as customer phone is provided and contact has not ended"
      );
      console.log("AGGEWIV: ", props.customerNumber);
      console.log("AGGEWIV: ", phoneValue);
      console.log("JGEIGEN PAYLOAD: ", form.getFieldValue(textArea));

      try {
        console.log(props.customerNumber)
      } catch (error) {
        console.log("SMSERROR: ", error);
      }
    } else {
      console.log("Setting SMS payload as contact has ended");
      try {
        form.setFieldsValue({
          amount: "",
          queue: "",
          capacityType: "temporary",
          closeQueues: false
        });
      } catch (error) {
        console.log("SMSERROR: ", error);
      }

      var event = new CustomEvent("refreshContact", {
        detail: { contactEnded: "" },
      });
      window.dispatchEvent(event);
    }
  }, [props.customerNumber, props.contactEnded, props.contactID]);

  // useEffect(()=>{
  //     console.log(selectedOption);
  // },[selectedOption])

  const onFinishFailed = () => {
    message.error("Please fill required fields.");
  };

  const onClear = () => {
    setObject({});
    setAmountValue("");
    setQueueValue("")
    setProgLink("");
    setProgValue("");
    setCapacityTypeVal("temporary")
    setSelectedOption("");
    setIsSpanish(false);
    form.setFieldsValue({
      amount: "",
      queue: "",
      capacityType: "temporary",
      closeQueues: false
    });
  };


  const handleUpdate = (e) => {
    setAmountValue(e.target.value)
    var textAreaMessage = e.target.value;
    console.log("MESSAGE: ", textAreaMessage)
    var updatedMessage = selectedOption + "\n" + textAreaMessage;
    console.log(updatedMessage);
    setIsSpanish(false);
    form.setFieldsValue({
      amount: textAreaMessage,
    });
  }


  function CapacityList(props) {
    console.log("PROPS CAPACITY LIST: ", props)
    function onDropChangeCapacity(event) {
      const { value } = event.target;
      console.log("ON Capacity Type CHANGE ENGAGED");

      // find the program with the current selected value
      const type = props.type.find((c) => c.name === value);
      console.log("capacity type value: ", type.value)



      if (type !== undefined) {
        setCapacityTypeVal(type.value)
        // use selected program object here
        form.setFieldsValue({
          capacityType: type.value
        });
      }
    }

    return (
      <>
        <input
          list="capacity"

          name="options"
          placeholder={capacityTypeVal}
          onChange={onDropChangeCapacity}
        />
        <datalist id="capacity">
          {props.type.map((type) => (
            <option
              key={type.value}
              data-value={type.value}
              value={type.name}
            />
          ))}
        </datalist>
      </>
    );
  }



  function DataList(props) {
    console.log("PROPS DATA LIST: ", props)
    function onDropChange(event) {
      const { value } = event.target;
      console.log("ON DROP CHANGE ENGAGED");

      // find the program with the current selected value
      const program = props.programs.find((c) => c.name === value);
      console.log("program value: ", program.value)



      if (program !== undefined) {
        setQueueValue(program.value)
        // use selected program object here
        setProgramValue(program.value);
        setProgValue(program.value);
        form.setFieldsValue({
          queue: program.value
        });
      }
    }

    return (
      <>
        <input
          list="dropdown"

          name="options"
          placeholder={progValue}
          onChange={onDropChange}
        />
        <datalist id="dropdown">
          {props.programs.map((program) => (
            <option
              key={program.value}
              data-value={program.value}
              value={program.name}
            />
          ))}
        </datalist>
      </>
    );
  }

  return (
    <div style={{ maxHeight: "430px", paddingLeft: '25px', paddingRight: '25px', overflow: "auto" }}>
     {/* <Card title="Adjust Capacity" hoverable> */}
        <div className="metric-layout">
          <Table dataSource={dataSource} columns={columns} />
        </div>

        <Form
          disabled={isFecthingData}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <Form.Item
          name={phone}
          label="Phone Number"
          rules={[
            {
              required: true,
              pattern: REGEX.IS_PHONE_NUMBER,
              amount: "",
            },
          ]}
        >
          <Input onChange={handlePhoneChange} placeholder="###-###-####" />
        </Form.Item>
        <Form.Item>
          <Segmented
            block
            options={["General", "Interview", "FCCC", "Custom"]}
            onChange={handleSectionChange}
          />
        </Form.Item> */}

          {/* <Select
          placeholder="Select or search for a template amount"
          options={
            selectedValue1 == "General"
              ? templates
              : selectedValue1 == "Interview"
              ? interviewTemplates
              : selectedValue1 == "FCCC"
              ? fcccTemplates
              : customTemplates
          }
          value={selectedOption}
          //   onChange={selectedValue1 == "Custom" ? null : handleChange}
          onChange={handleChange}
          maxMenuHeight={150}
        /> */}

          <Space style={{ marginTop: '0px', marginBottom: '50px' }}>
            {capacityTypeVal == 'permanent' ?
              <>
                <label>Change</label>
              </>
              :
              <>
                <label>Increase</label>
              </>}
            {/* <input
          list="dropdown"
           
          name="options" 
          placeholder={capacityTypeVal}
          onChange={onDropChangeCapacity}
        />
        <datalist id="capacitydropdown">
          {capacityDropdown.map((type) => (
            <option
              key={type.value}
              data-value={type.value}
              value={type.name}
            />
          ))}
        </datalist>

        <input list="browsers" name="browser" id="browser"/>

        <datalist id="browsers">
          <option value="Edge">
          <option value="Firefox">
          <option value="Chrome">
          <option value="Opera">
          <option value="Safari">
        </datalist> */}
            <CapacityList type={capacityDropdown} />
            <label> capacity of </label>
            <DataList programs={queueDropdown} />
            {capacityTypeVal == 'permanent' ?
              <>
                <label>to</label>
              </>
              :
              <>
                <label>by</label>
              </>}


            <TextArea value={amountValue} onChange={handleUpdate} rows={1} style={{ maxWidth: '90%' }} placeholder={"amount to add"} />
          </Space>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {/* <Button htmlType="button" onClick={onFill}>
                            Templates
                        </Button> */}
              <Button htmlType="button" onClick={onClear}>
                Clear
              </Button>
              <Button htmlType="button" onClick={onCloseQueues}>
                Close All Queues
              </Button>
              <Button htmlType="button" onClick={() => { setIsRefreshing(true) }}>
                Refresh Queue Metrics
              </Button>
            </Space>
          </Form.Item>
        </Form>
        {/* </Card> */}
    </div>
  );
};
export default Metrics;
