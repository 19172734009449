import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, Form, Input, message, Space, Card, Segmented } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { queueDropdown } from '../../assets/queueDropdown';
import { capacityDropdown } from '../../assets/capacityDropdown';
import axios from 'axios';
import 'antd/dist/reset.css';
import './Dashboard.css'; // Import the CSS file
const { TextArea } = Input;

const { Title } = Typography;

const dummyData = [
  {
    key: '1',
    queueName: 'HSA',
    phoneNumber: '+1234567890',
    waitTime: '00:05:32',
    position: '1',
    contacted: true,
    answered: false,
  },
  {
    key: '2',
    queueName: 'Red',
    phoneNumber: '+1987654321',
    waitTime: '00:02:15',
    position: '2',
    contacted: true,
    answered: false,
  },
  {
    key: '2',
    queueName: 'Purple',
    phoneNumber: '+1987654321',
    waitTime: '00:02:17',
    position: '3',
    contacted: false,
    answered: false,
  },
  {
    key: '2',
    queueName: 'Green',
    phoneNumber: '+1987654321',
    waitTime: '00:02:15',
    position: '4',
    contacted: true,
    answered: true,
  },
  {
    key: '2',
    queueName: 'Blue',
    phoneNumber: '+1787654321',
    waitTime: '00:02:15',
    position: '5',
    contacted: false,
    answered: false,
  },
  {
    key: '2',
    queueName: 'Purple',
    phoneNumber: '+1202654321',
    waitTime: '00:40:15',
    position: '6',
    contacted: true,
    answered: false,
  },
  {
    key: '2',
    queueName: 'IR',
    phoneNumber: '+1887654321',
    waitTime: '00:40:15',
    position: '7',
    contacted: true,
    answered: true,
  },
  {
    key: '2',
    queueName: 'Geen',
    phoneNumber: '+17041654321',
    waitTime: '00:40:15',
    position: '8',
    contacted: true,
    answered: false,
  },
  {
    key: '2',
    queueName: 'Red',
    phoneNumber: '+13501654321',
    waitTime: '00:40:15',
    position: '9',
    contacted: true,
    answered: true,
  },
  {
    key: '2',
    queueName: 'HSA',
    phoneNumber: '+12301654321',
    waitTime: '00:40:15',
    position: '10',
    contacted: true,
    answered: true,
  },
];

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');



  const textArea = "amount";
  const queueArea = "queue";
  const capacityArea = "capacityType";
  const [textValue, setTextValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("General");
  const [selectedOption, setSelectedOption] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [amountValue, setAmountValue] = useState("");
  const [queueValue, setQueueValue] = useState("");
  const [contactID, setContactID] = useState("");
  const [routingProfile, setRoutingProfile] = useState("");
  const [agentID, setAgentID] = useState("");
  const [xId, setXId] = useState("");
  const [searchMode, setSearchMode] = useState("contains");
  const [form] = Form.useForm();
  const [payLoad, setpayLoad] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSpanish, setIsSpanish] = useState(false);
  const [objValue, setObject] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [progValue, setProgValue] = useState("select queue");
  const [capacityTypeVal, setCapacityTypeVal] = useState("temporary");
  const [programValue, setProgramValue] = useState();
  const [progLink, setProgLink] = useState("");
  const [caseNumber, setCaseNumber] = useState("")
  
  const [dataSource, setDataSource] = useState([{
    red: "10/20",
    green: "13/15",
    blue: "2/10",
    ir: "14/20",
    purple: "20/20",
    hsa: "83/100",
    lieap: "22/30"
  }])

  const onFinish = () => {
    // var newPayload = {
    //   amount: form.getFieldValue(textArea),
    //   queue: form.getFieldValue(queueArea),
    //   capacityType: form.getFieldValue(capacityArea),
    //   closeQueues: false
    //   // agentID: agentID,
    //   // xID: xId,
    // };

    // console.log("NEW PAYLOAD: ", newPayload)
    // if (validateAmount(amountValue) && queueValue.length) {
    //   console.log("AMT AREA PAYLOAD: ", amountValue);
    //   console.log("QUE Area PAYLOAD: ", queueValue);
    //   //setpayLoad(ConvertToFormUrlEncoded(form.getFieldsValue()));
    //   var newPayload = {
    //     amount: form.getFieldValue(textArea),
    //     queue: form.getFieldValue(queueArea),
    //     capacityType: form.getFieldValue(capacityArea),
    //     closeQueues: false
    //     // agentID: agentID,
    //     // xID: xId,
    //   };
    //   setpayLoad(newPayload);
    //   setAmountValue("")
    //   setQueueValue("")
    //   setProgValue("select queue")
    //   setProgramValue("")
    //   setCapacityTypeVal("temporary")
    //   form.setFieldsValue({
    //     amount: "",
    //     queue: "",
    //     capacityType: "temporary",
    //     closeQueues: false
    //   });
    //   console.log("FORM VALUE: ", form);
    //   console.log("New Payload: ", newPayload);
    //   setIsRefreshing(true)
    // }
  };

  const onFinishFailed = () => {
    message.error("Please fill required fields.");
  };

  const onClear = () => {
    setObject({});
    setAmountValue("");
    setQueueValue("")
    setProgLink("");
    setProgValue("");
    setCapacityTypeVal("temporary")
    setSelectedOption("");
    setIsSpanish(false);
    form.setFieldsValue({
      amount: "",
      queue: "",
      capacityType: "temporary",
      closeQueues: false
    });
  };


  const handleUpdate = (e) => {
    setAmountValue(e.target.value)
    var textAreaMessage = e.target.value;
    console.log("MESSAGE: ", textAreaMessage)
    var updatedMessage = selectedOption + "\n" + textAreaMessage;
    console.log(updatedMessage);
    setIsSpanish(false);
    form.setFieldsValue({
      amount: textAreaMessage,
    });
  }


  function CapacityList(props) {
    console.log("PROPS CAPACITY LIST: ", props)
    function onDropChangeCapacity(event) {
      const { value } = event.target;
      console.log("ON Capacity Type CHANGE ENGAGED");

      // find the program with the current selected value
      const type = props.type.find((c) => c.name === value);
      console.log("capacity type value: ", type.value)



      if (type !== undefined) {
        setCapacityTypeVal(type.value)
        // use selected program object here
        form.setFieldsValue({
          capacityType: type.value
        });
      }
    }

    return (
      <>
        <input
          list="capacity"

          name="options"
          placeholder={capacityTypeVal}
          onChange={onDropChangeCapacity}
        />
        <datalist id="capacity">
          {props.type.map((type) => (
            <option
              key={type.value}
              data-value={type.value}
              value={type.name}
            />
          ))}
        </datalist>
      </>
    );
  }



  function DataList(props) {
    console.log("PROPS DATA LIST: ", props)
    function onDropChange(event) {
      const { value } = event.target;
      console.log("ON DROP CHANGE ENGAGED");

      // find the program with the current selected value
      const program = props.programs.find((c) => c.name === value);
      console.log("program value: ", program.value)



      if (program !== undefined) {
        setQueueValue(program.value)
        // use selected program object here
        setProgramValue(program.value);
        setProgValue(program.value);
        form.setFieldsValue({
          queue: program.value
        });
      }
    }

    return (
      <>
        <input
          list="dropdown"

          name="options"
          placeholder={progValue}
          onChange={onDropChange}
        />
        <datalist id="dropdown">
          {props.programs.map((program) => (
            <option
              key={program.value}
              data-value={program.value}
              value={program.name}
            />
          ))}
        </datalist>
      </>
    );
  }

  const columns = [
    {
      title: 'Red Queue',
      dataIndex: 'red',
      key: 'red',
    },
    {
      title: 'Green Queue',
      dataIndex: 'green',
      key: 'green',
    },
    {
      title: 'Blue Queue',
      dataIndex: 'blue',
      key: 'blue',
    },
    {
      title: 'IR Queue',
      dataIndex: 'ir',
      key: 'ir',
    },
    {
      title: 'Purple Queue',
      dataIndex: 'purple',
      key: 'purple',
    },
    {
      title: 'HSA Queue',
      dataIndex: 'hsa',
      key: 'hsa',
    },
    {
      title: 'LIEAP Queue',
      dataIndex: 'lieap',
      key: 'lieap',
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      //const response = await axios.get('YOUR_API_GATEWAY_INVOKE_URL');
      //const queueData = response.data.map((item, index) => ({
        //key: index,
        //position: index + 1,
        //...item,
      //}));
      setData(dummyData);
    } catch (error) {
      console.error('Error fetching data', error);
    }
    setLoading(false);
  };
    useEffect(() => {
    // Simulating data fetch
    setLoading(false);
    setData(dummyData);
  }, []);

  const handleRemove = (key) => {
    const newData = data.filter(item => item.key !== key);
    setData(newData);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 300000); // Refresh every 5 minutes
    return () => clearInterval(interval);
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columnsDashboard = [
    { title: 'Queue Name', dataIndex: 'queueName', key: 'queueName', ...getColumnSearchProps('queueName'), className: 'column-title' },
    { title: "Caller's Phone Number", dataIndex: 'phoneNumber', key: 'phoneNumber', ...getColumnSearchProps('phoneNumber'), className: 'column-title' },
    { title: 'Elapsed Wait Time', dataIndex: 'waitTime', key: 'waitTime', ...getColumnSearchProps('waitTime'), className: 'column-title' },
    { title: 'Caller Position', dataIndex: 'position', key: 'position', ...getColumnSearchProps('position'), className: 'column-title' },
    {
      title: 'Caller Contacted',
      dataIndex: 'contacted',
      key: 'contacted',
      ...getColumnSearchProps('contacted'),
      className: 'column-title',
      render: (text) => (
        <span style={{ color: text ? 'blue' : 'red' }}>
          {text ? 'Yes' : 'No'}
        </span>
      ),
    },
    {
      title: 'Caller Answered',
      dataIndex: 'answered',
      key: 'answered',
      ...getColumnSearchProps('answered'),
      className: 'column-title',
      render: (text) => (
        <span style={{ color: text ? 'blue' : 'red' }}>
          {text ? 'Yes' : 'No'}
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" danger onClick={() => handleRemove(record.key)}>
          Remove
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 24, backgroundColor: '#f0f2f5' }}>
      {/* <Card title="Callback Data" hoverable> */}
      <Table 
      
      rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
      columns={columnsDashboard}  dataSource={data} loading={loading} pagination={{ pageSize: 5 }} />
      

      {/* </Card> */}
    </div>
  );
};

export default Dashboard;
