import { Row, Col, Form, Checkbox, Input, Select } from "antd";
import { useState } from "react";
const { Option } = Select;
const NONCUSTPARENTLIST = (props) => {
  const [textValue, setTextValue] = useState("");

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      labelAlign="justify"
      layout="vertical"
      labelWrap
      wrapperCol={{
        flex: 1,
      }}
    >
      <Row>
        <Col span={16} style={{ fontWeight: "bold" }}>
          Screen Name: NONCUSTODIAL PARENT LIST (All Programs)
        </Col>
        <Col span={8}>
          <Form.Item
            name="Status"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select defaultValue="Selected Status" style={{ width: 200 }}>
              <Option value="Selected Status">
                <strong>Selected Status</strong>
              </Option>
              <Option value="N/A">N/A</Option>
              <Option value="COMPLETE">COMPLETE</Option>
              <Option value="PENDING">PENDING</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <label>
        In order, to be eligible for cash, childcare and/or food assistance you
        are required to cooperate with child support services to establish
        paternity and work with them to begin/establish enforcement. This is a
        mandatory requirement. If this will put you or your child/children in
        danger of abuse, or if you have other good reasons why you cannot
        cooperate, please tell me.
      </label>
      <Row>
        <label>Request to withdraw application. Programs withdrawn:</label>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - Good Cause:"
          >
            <Checkbox value="Good Cause">
              {
                <a
                  href="http://content.dcf.ks.gov/ees/KEESM/Current/keesm2530.htm"
                  target="_blank"
                >
                  Good Cause
                </a>
              }
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - Referral Made:"
          >
            <Checkbox value="Referral Made">Referral Made</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            valuePropName="checked"
            name="Request to withdraw application. Programs withdrawn - Expedited Paternity:"
          >
            <Checkbox value="Expedited Paternity">Expedited Paternity</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="Comments" label="Comments">
        <Input style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="Pending For:" label="Pending For:">
        <Input style={{ width: "100%" }} />
      </Form.Item>
    </Form>
  );
};
export default NONCUSTPARENTLIST;
